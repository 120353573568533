import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import * as personTypes from './person/personTypes';
import * as configurationTypes from './configuration/configurationTypes';
import * as insuranceTypes from './insurance/insuranceTypes';
import * as offerTypes from './offer/offerTypes';
import * as simulationTypes from './simulation/simulationTypes';
import * as errorTypes from './error/errorTypes';
import * as bankDetailsTypes from './bankDetails/bankDetailsTypes';
import * as documentsSynchronizationTypes from './documentsSynchronization/documentsSynchronizationTypes';
import * as controlEmailTypes from './controlEmail/controlEmailTypes';
import * as switchdeviceTypes from './switchDevice/switchDeviceTypes';
import * as synchronizeTypes from './synchronize/synchronizeTypes';

import personReducer, {
  selectPerson,
  updatePerson,
  clear as clearPerson,
  clearCoEmprunteur as clearPersonCoEmprunteur,
  updatePersonCoEmprunteur,
  updatePersonEmprunteur,
  updateDocumentsEmprunteur,
  updateDocumentsCoEmprunteur,
  setOcrKo,
} from './person/personSlice';
import configurationReducer, {
  setConfiguration,
  updateConfiguration,
  selectConfiguration,
  clear as clearConfiguration,
} from './configuration/configurationSlice';
import offerReducer, {
  setOffer,
  updateOffer,
  selectOffer,
  clear as clearOffer,
} from './offer/offerSlice';
import simulationReducer, {
  setSimulation,
  updateSimulation,
  clear as clearSimulation,
  selectSimulation,
  clearExceptBornesAmount as clearSimulationExceptBornesAmount,
} from './simulation/simulationSlice';
import bankDetailsReducer, {
  setBankDetails,
  updateBankDetails,
  selectBankDetails,
  clear as clearBankDetails,
} from './bankDetails/bankDetailsSlice';
import documentsSynchronizationReducer, {
  setDocumentsSynchronization,
  updateDocumentsSynchronization,
  selectDocumentsSynchronization,
  clear as clearDocumentsSynchronization,
} from './documentsSynchronization/documentsSynchronizationSlice';
import controlEmailReducer, {
  setControlEmail,
  updateControlEmail,
  selectControlEmail,
  clear as clearControlEmail,
} from './controlEmail/controlEmailSlice';
import switchDeviceReducer, {
  setSwitchDevice,
  updateSwitchDevice,
  selectSwitchDevice,
  clear as clearSwitchDevice,
} from './switchDevice/switchDeviceSlice';
import souscriptionApiSlice, {
  souscriptionApiMiddleware,
  souscriptionApiReducer,
  useLazyGetParametersQuerySynchronized,
  useLazyGetCountriesQuerySynchronized,
  useLazyGetDepartmentsQuerySynchronized,
  useLazyGetDepartmentsByCityQuerySynchronized,
  useLazyGetCitiesQuerySynchronized,
  useLazyGetCitiesByZipCdQuerySynchronized,
  useSendOffreCommercialeMutation,
  useLazyGetDocumentsSynchroQuerySynchronized,
  useSendEmprunteurMutation,
  useSendCoEmprunteurMutation,
  useLazyGetInsurancesBorrowerQuerySynchronized,
  useSendInsuranceBorrowerMutation,
  useLazyGetInsurancesCoBorrowerQuerySynchronized,
  useSendInsuranceCoBorrowerMutation,
  useSendContratPapierMutation,
  useSendContratElectroniqueMutation,
  useLazyGetScoreQuerySynchronized,
  useSendDocumentMutation,
  useSendAnalyseDocumentMutation,
  useSendDeleteDocumentMutation,
  useSendModifyBorrowerMutation,
  useSendModifyBorrowerWithoutErrorMutation,
  souscriptionEndpointsToNotCheck,
  useSendBankInformationsMutation,
  useSendCreateSouscriptionMutation,
  useLazyGetValidationSirenNumberQuerySynchronized,
  useSendLogsMutation,
  useGetAuthCheckQuery,
  useLazyGetAuthCheckQuerySynchronized,
  useLazyGetEmprunteurInformationsQuerySynchronized,
  useLazyGetCoEmprunteurInformationsQuerySynchronized,
  useLazyGetBankInformationsQuerySynchronized,
  useLazyGetTokenDebranchementQRQuerySynchronized,
  useSendConsultSwitchMobileMutation,
  useSendAjoutEvenementSwitchMobileMutation,
  useLazyGetControleEmailQuerySynchronized,
  useDeleteDocumentCancelMutation,
  useLazyGetRecapitulatifQuerySynchronized,
} from './souscriptionApi';
import authGwApiSlice, {
  authGwApiMiddleware,
  authGwApiReducer,
  useLazyGetLogoutQuery,
  authgwEndpointsToNotCheck,
} from './authGwApi/authGwApiSlice';

import simulationApiSlice, {
  simulationApiMiddleware,
  simulationApiReducer,
  useSendSimulationDetailleeMutation,
  useSendSimulationSimpleMutation,
} from './simulationApi/simulationApiSlice';

import mentionLegalesApiSlice, {
  mentionLegalesApiMiddleware,
  mentionLegalesApiReducer,
  useLazyGetMentionsLegalesQuery,
} from './mentionLegalesApi/mentionLegalesApiSlice';
import {
  IEvenementSwitchMobile,
  IDocumentsUploaded,
  IBlockAttendu,
  IDocumentsAttendus,
} from './souscriptionApi/souscriptionApiTypes';

import insuranceReducer, {
  setInsurances,
  updateInsurances,
  selectInsurances,
  updateInsuranceSelectedEmprunteur,
  updateInsuranceSelectedCoemprunteur,
  clearInsurances as clearInsurances,
  clearInsuranceCoEmprunteur,
} from './insurance/insuranceSlice';

import synchronizeReducer, {
  resetSynchronize,
  responseSynchronize,
  selectSynchronize,
  clear as clearSynchronize,
} from './synchronize/synchronizeSlice';
import errorReducer, {
  setError,
  selectError,
  clearError,
} from './error/errorSlice';
import { rtkQueryUtils, getDynamicQueriesWithRetries } from './rtkQueryUtils';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

interface IStoreState {
  person: { state: personTypes.IState };
  configuration: { state: configurationTypes.IState };
  offer: { state: offerTypes.IState };
  simulation: { state: simulationTypes.IState };
  insurance: { state: insuranceTypes.IState };
  error: { state: errorTypes.IState };
  bankDetails: { state: bankDetailsTypes.IState };
  documentsSynchronization: { state: documentsSynchronizationTypes.IState };
  controlEmail: { state: controlEmailTypes.IState };
  switchDevice: { state: switchdeviceTypes.IState };
  synchronize: { state: synchronizeTypes.IState };
}

const rootReducer = combineReducers({
  person: personReducer,
  configuration: configurationReducer,
  offer: offerReducer,
  simulation: simulationReducer,
  souscriptionApi: souscriptionApiReducer,
  simulationApi: simulationApiReducer,
  authGwApi: authGwApiReducer,
  mentionLegalesApi: mentionLegalesApiReducer,
  insurance: insuranceReducer,
  error: errorReducer,
  bankDetails: bankDetailsReducer,
  documentsSynchronization: documentsSynchronizationReducer,
  controlEmail: controlEmailReducer,
  switchDevice: switchDeviceReducer,
  synchronize: synchronizeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const { rtkQueryErrorLogger } = rtkQueryUtils([
  ...souscriptionEndpointsToNotCheck,
  ...authgwEndpointsToNotCheck,
  /* Add other endpoints (as simulation endpoints) to not check here */
]);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(souscriptionApiMiddleware)
      .concat(simulationApiMiddleware)
      .concat(authGwApiMiddleware)
      .concat(mentionLegalesApiMiddleware)
      .concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

const clearStore = () => {
  // Reset le store
  store.dispatch(clearPerson());
  store.dispatch(clearConfiguration());
  store.dispatch(clearOffer());
  store.dispatch(clearSimulation());
  store.dispatch(clearInsurances());
  store.dispatch(clearError());
  store.dispatch(clearBankDetails());
  store.dispatch(clearDocumentsSynchronization());
  store.dispatch(clearControlEmail());
  store.dispatch(clearSwitchDevice());
  store.dispatch(clearSynchronize());
  // Reset des API
  store.dispatch(souscriptionApiSlice.util.resetApiState());
  store.dispatch(simulationApiSlice.util.resetApiState());
  store.dispatch(authGwApiSlice.util.resetApiState());
  store.dispatch(mentionLegalesApiSlice.util.resetApiState());
};

const updateStore = (storeData: IStoreState) => {
  store.dispatch(updatePerson(storeData.person.state));
  store.dispatch(updateConfiguration(storeData.configuration.state));
  store.dispatch(updateOffer(storeData.offer.state));
  store.dispatch(updateSimulation(storeData.simulation.state));
  store.dispatch(updateInsurances(storeData.insurance.state));
  store.dispatch(updateBankDetails(storeData.bankDetails.state));
  store.dispatch(
    updateDocumentsSynchronization(storeData.documentsSynchronization.state)
  );
  store.dispatch(updateControlEmail(storeData.controlEmail.state));
  store.dispatch(updateSwitchDevice(storeData.switchDevice.state));
};

export {
  updateStore,
  clearStore,
  /* Error */
  setError,
  selectError,
  clearError,
  /* Person */
  personTypes,
  updatePerson,
  updatePersonEmprunteur,
  updatePersonCoEmprunteur,
  clearPersonCoEmprunteur,
  updateDocumentsEmprunteur,
  updateDocumentsCoEmprunteur,
  selectPerson,
  setOcrKo,
  personReducer as person,
  /* Configuration */
  configurationTypes,
  setConfiguration,
  updateConfiguration,
  selectConfiguration,
  configurationReducer as configuration,
  /* Offer */
  offerTypes,
  setOffer,
  updateOffer,
  selectOffer,
  offerReducer as offer,
  /* Simulation */
  simulationTypes,
  setSimulation,
  updateSimulation,
  selectSimulation,
  simulationReducer as simulation,
  clearSimulation,
  clearSimulationExceptBornesAmount,
  /* BankDetails */
  bankDetailsTypes,
  setBankDetails,
  updateBankDetails,
  selectBankDetails,
  bankDetailsReducer as bankDetails,
  /* DocumentsSynchronization */
  documentsSynchronizationTypes,
  setDocumentsSynchronization,
  updateDocumentsSynchronization,
  selectDocumentsSynchronization,
  documentsSynchronizationReducer as documentsSynchronization,
  /* ControlEmail */
  controlEmailTypes,
  setControlEmail,
  updateControlEmail,
  selectControlEmail,
  controlEmailReducer as controlEmail,
  /* Insurance */
  setInsurances,
  updateInsurances,
  updateInsuranceSelectedEmprunteur,
  updateInsuranceSelectedCoemprunteur,
  insuranceTypes,
  selectInsurances,
  insuranceReducer as insurance,
  clearInsuranceCoEmprunteur,
  /*SwitchDevice*/
  switchdeviceTypes,
  setSwitchDevice,
  updateSwitchDevice,
  selectSwitchDevice,
  switchDeviceReducer as switchDevice,
  /* Synchronize */
  selectSynchronize,
  synchronizeTypes,
  resetSynchronize,
  responseSynchronize,
  synchronizeReducer as synchronize,
  /* mentionLegaleApi */
  useLazyGetMentionsLegalesQuery,
  /* SouscriptionApi */
  useLazyGetParametersQuerySynchronized,
  useLazyGetCountriesQuerySynchronized,
  useLazyGetDepartmentsQuerySynchronized,
  useLazyGetDepartmentsByCityQuerySynchronized,
  useLazyGetCitiesQuerySynchronized,
  useLazyGetDocumentsSynchroQuerySynchronized,
  useLazyGetCitiesByZipCdQuerySynchronized,
  useSendOffreCommercialeMutation,
  useSendLogsMutation,
  useSendEmprunteurMutation,
  useSendCoEmprunteurMutation,
  useLazyGetScoreQuerySynchronized,
  useLazyGetInsurancesBorrowerQuerySynchronized,
  useSendInsuranceBorrowerMutation,
  useLazyGetInsurancesCoBorrowerQuerySynchronized,
  useSendInsuranceCoBorrowerMutation,
  useSendContratPapierMutation,
  useSendContratElectroniqueMutation,
  useSendDocumentMutation,
  useSendAnalyseDocumentMutation,
  useSendDeleteDocumentMutation,
  useSendModifyBorrowerMutation,
  useSendModifyBorrowerWithoutErrorMutation,
  useSendBankInformationsMutation,
  useSendCreateSouscriptionMutation,
  useLazyGetValidationSirenNumberQuerySynchronized,
  useGetAuthCheckQuery,
  useLazyGetAuthCheckQuerySynchronized,
  useLazyGetEmprunteurInformationsQuerySynchronized,
  useLazyGetCoEmprunteurInformationsQuerySynchronized,
  useLazyGetBankInformationsQuerySynchronized,
  useLazyGetTokenDebranchementQRQuerySynchronized,
  useSendConsultSwitchMobileMutation,
  useSendAjoutEvenementSwitchMobileMutation,
  useLazyGetControleEmailQuerySynchronized,
  useDeleteDocumentCancelMutation,
  useLazyGetRecapitulatifQuerySynchronized,
  /* SimulationApi */
  useSendSimulationSimpleMutation,
  useSendSimulationDetailleeMutation,
  /* AuthGwApi */
  useLazyGetLogoutQuery,
  /* utils */
  getDynamicQueriesWithRetries,
};
export type {
  IStoreState,
  IEvenementSwitchMobile,
  IDocumentsUploaded,
  IBlockAttendu,
  IDocumentsAttendus,
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
