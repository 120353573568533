import {
  IDocumentsAttendus,
  selectConfiguration,
  selectDocumentsSynchronization,
  selectOffer,
  updateConfiguration,
  updateDocumentsSynchronization,
  updateOffer,
  useAppDispatch,
  useAppSelector,
  useLazyGetDocumentsSynchroQuerySynchronized,
} from '@store';
import { EIsExpected } from '@types';
import { useEffect } from 'react';

const useSynchronizeDocuments = () => {
  const { souscriptionId } = useAppSelector(selectConfiguration);
  const { send: triggerDocumentsUploaded, response: resultDocumentsUploaded } =
    useLazyGetDocumentsSynchroQuerySynchronized();
  const { synchronizedFiles } = useAppSelector(selectDocumentsSynchronization);
  const offer = useAppSelector(selectOffer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!resultDocumentsUploaded?.data) {
      return;
    }
    const documents = resultDocumentsUploaded?.data;
    const hasCoBorrowerBySynchro =
      (documents?.identite_co_emprunteur?.attendu &&
        documents?.identite_co_emprunteur?.attendu !==
          EIsExpected.NON_ATTENDU) ||
      (documents?.revenus_co_emprunteur?.attendu &&
        documents?.revenus_co_emprunteur?.attendu !==
          EIsExpected.NON_ATTENDU) ||
      (documents?.domicile_co_emprunteur?.attendu &&
        documents?.domicile_co_emprunteur?.attendu !== EIsExpected.NON_ATTENDU);
    dispatch(
      updateOffer({
        inputBorrowers: hasCoBorrowerBySynchro ? '2' : '1',
      })
    );

    dispatch(
      updateDocumentsSynchronization({
        synchronizedFiles: resultDocumentsUploaded.data as IDocumentsAttendus,
      })
    );
    dispatch(
      updateConfiguration({
        isLoading: false,
      })
    );
  }, [resultDocumentsUploaded]);

  const sendDocumentsSynchronization = () => {
    if (!souscriptionId) {
      return;
    }
    dispatch(
      updateConfiguration({
        isLoading: !!(!offer?.inputLoanAmount && !synchronizedFiles),
      })
    );
    triggerDocumentsUploaded(souscriptionId);
  };

  return { sendDocumentsSynchronization };
};

export default useSynchronizeDocuments;
