import souscriptionApiSlice from './souscriptionApiSlice';
import * as useLazyGetSynchronized from './useLazyGetSynchronized';

export {
  souscriptionApiMiddleware,
  souscriptionApiReducer,
  souscriptionEndpointsToNotCheck,
} from './souscriptionApiSlice';

export const {
  useSendOffreCommercialeMutation,
  useSendLogsMutation,
  useSendEmprunteurMutation,
  useSendInsuranceBorrowerMutation,
  useSendInsuranceCoBorrowerMutation,
  useSendContratPapierMutation,
  useSendContratElectroniqueMutation,
  useSendCoEmprunteurMutation,
  useSendDocumentMutation,
  useSendAnalyseDocumentMutation,
  useSendDeleteDocumentMutation,
  useSendModifyBorrowerMutation,
  useSendModifyBorrowerWithoutErrorMutation,
  useDeleteDocumentCancelMutation,
  useSendBankInformationsMutation,
  useSendCreateSouscriptionMutation,
  useSendAjoutEvenementSwitchMobileMutation,
  useSendConsultSwitchMobileMutation,
  useGetAuthCheckQuery,
} = souscriptionApiSlice;

export const {
  useLazyGetParametersQuerySynchronized,
  useLazyGetCountriesQuerySynchronized,
  useLazyGetDepartmentsQuerySynchronized,
  useLazyGetDepartmentsByCityQuerySynchronized,
  useLazyGetCitiesQuerySynchronized,
  useLazyGetCitiesByZipCdQuerySynchronized,
  useLazyGetRecapitulatifQuerySynchronized,
  useLazyGetDocumentsSynchroQuerySynchronized,
  useLazyGetInsurancesBorrowerQuerySynchronized,
  useLazyGetInsurancesCoBorrowerQuerySynchronized,
  useLazyGetScoreQuerySynchronized,
  useLazyGetValidationSirenNumberQuerySynchronized,
  useLazyGetAuthCheckQuerySynchronized,
  useLazyGetEmprunteurInformationsQuerySynchronized,
  useLazyGetCoEmprunteurInformationsQuerySynchronized,
  useLazyGetBankInformationsQuerySynchronized,
  useLazyGetTokenDebranchementQRQuerySynchronized,
  useLazyGetControleEmailQuerySynchronized,
} = useLazyGetSynchronized;

export default souscriptionApiSlice;
