export enum SynchronizeName {
  EMPRUNTEUR_INFORMATIONS_SYNC = 'INFORMATIONS_EMPRUNTEUR',
  CO_EMPRUNTEUR_INFORMATIONS_SYNC = 'INFORMATIONS_CO_EMPRUNTEUR',
  BANK_INFORMATIONS_INFORMATIONS_SYNC = 'INFORMATIONS_BANK_INFORMATIONS',
  GET_CITIES_INFORMATIONS = 'GET_CITIES_INFORMATIONS',
  GET_CONTROLE_EMAIL_QUERY = 'useLazyGetControleEmailQuery',
  GET_ID_DEBRANCHEMENT_QR_QUERY = 'useLazyGetIdDebranchementQRQuery',
  GET_BANK_INFORMATIONS_QUERY = 'useLazyGetBankInformationsQuery',
  GET_COEMPRUNTEUR_INFORMATIONS_QUERY = 'useLazyGetCoEmprunteurInformationsQuery',
  GET_EMPRUNTEUR_INFORMATIONS_QUERY = 'useLazyGetEmprunteurInformationsQuery',
  GET_AUTH_CHECK_QUERY = 'useLazyGetAuthCheckQuery',
  GET_VALIDATION_SIREN_NUMBER_QUERY = 'useLazyGetValidationSirenNumberQuery',
  GET_SCORE_QUERY = 'useLazyGetScoreQuery',
  GET_INSURANCES_COBORROWER_QUERY = 'useLazyGetInsurancesCoBorrowerQuery',
  GET_INSURANCES_BORROWER_QUERY = 'useLazyGetInsurancesBorrowerQuery',
  GET_DOCUMENTS_SYNCHRO_QUERY = 'useLazyGetDocumentsSynchroQuery',
  GET_RECAPITULATIF_QUERY = 'useLazyGetRecapitulatifQuery',
  GET_CITIES_BY_ZIPCD_QUERY = 'useLazyGetCitiesByZipCdQuery',
  GET_CITIES_QUERY = 'useLazyGetCitiesQuery',
  GET_DEPARTMENTS_BY_CITY_QUERY = 'useLazyGetDepartmentsByCityQuery',
  GET_DEPARTMENTS_QUERY = 'useLazyGetDepartmentsQuery',
  GET_COUNTRIES_QUERY = 'useLazyGetCountriesQuery',
  GET_PARAMETERS_QUERY = 'useLazyGetParametersQuery',
}

export interface ISynchronize {
  name: SynchronizeName;
  response: unknown;
  isSaving: boolean;
  isAttempting: boolean;
  isSuccess: boolean;
  request?: unknown;
  attemptCount: number;
}

export interface IState {
  synchro: ISynchronize[];
}

export interface ISynchronizeResponse<TResponse> extends ISynchronize {
  response: TResponse;
}

export interface ISynchronizeRequest<TRequest> extends ISynchronize {
  request: TRequest;
}

export interface ISynchronizeFullyTyped<TRequest, TResponse>
  extends ISynchronize {
  response: TResponse;
  request: TRequest;
}
